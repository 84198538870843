<template>
  <div class="product-container">
    <!-- banner_cp -->
    <div class="banner_cp" style="background-color: #f6f6f6">
      <div class="margin width1480 hidden">
        <div class="l">
          <dl style="display: block">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_shz.png"
            />
          </dl>
        </div>
        <div class="r">
          <div class="bg a-fdB">
            <dd>守 护 者</dd>
            <dl>
              <p>国货精选 | 卓越品质 | 支持共享 | 身份识别</p>
            </dl>
            <ol class="f">
              <dt class="o">
                <span><i style="background-color: #141414"></i></span>
                <p>黑色</p>
              </dt>
            </ol>
            <a @click="$U.openProductFile('shouhuzhe')">产品手册</a>
          </div>
        </div>
      </div>
    </div>
    <!-- shz_xzw -->
    <div id="g1">
      <div
        class="shz_xzw"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_xzw_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <!-- <dd class="cp_bt_y">性之王</dd> -->
            <dl class="cp_bt_d">极致工艺 高能有颜</dl>
            <dd class="cp_bt_y">高颜值呼吸灯 充电状态一目了然</dd>
          </div>
          <div class="f">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_xzw.png"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- shz_nhj -->
    <div id="g2">
      <div
        class="shz_nhj"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_nhj_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <dd>耐候级PC+ABS材质外壳</dd>
            <dl>防雨防冻耐高温耐老化，经受室外气候的考验</dl>
          </div>
          <div class="f">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_nhj.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- cp_aqfh -->
  <div id="g3">
    <div class="margin width1480 hidden">
      <ul class="cp_aqfh">
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh1.png"
              />
            </ol>
            <dd>过压/欠压保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh2.png"
              />
            </ol>
            <dd>过流保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh3.png"
              />
            </ol>
            <dd>雷电保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh4.png"
              />
            </ol>
            <dd>接地保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh5.png"
              />
            </ol>
            <dd>防漏电保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh6.png"
              />
            </ol>
            <dd>短路保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh7.png"
              />
            </ol>
            <dd>环境电源异常数据记录</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images/cp_aqfh8.png"
              />
            </ol>
            <dd>IP55</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh9.png"
              />
            </ol>
            <dd>过温保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh10.png"
              />
            </ol>
            <dd>预约充电</dd>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- jg_gscd -->
  <div id="g4">
    <div
      class="shz_jzxx"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jzxx_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="t">
          <dd class="cp_bt_d">紧致小巧，使用便捷</dd>
          <dl class="cp_bt_y">
            适用于国内推出的纯电动和插电式油电混合动力汽车
          </dl>
        </div>
        <div class="f">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jzxx.png"
            class="c"
          />
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jzxx_y.png"
            class="y"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- jg_znjc -->
  <div id="g5">
    <div class="shz_znjc_tit">
      <dd class="cp_bt_d">智能交互 随心来电</dd>
      <dl class="cp_bt_y">三种充电模式</dl>
    </div>
    <div
      class="shz_yzyk"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_yzyk_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_yzyk.png"
          class="tp"
        />
        <div class="r">
          <dd class="cp_bt_d">一桩一卡</dd>
          <dl class="cp_bt_y">配备防盗功能，匹配唯一身份识别智充卡启动</dl>
        </div>
      </div>
    </div>
  </div>
  <!-- xhx_zyqh -->
  <div id="g6">
    <!-- <div class="shz_gbqk" style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_gbqk_bg.jpg);">
      <div class="margin width1480 hidden">
          <div class="l">
              <dd class="cp_bt_d">国标7孔充电头</dd>
              <dl class="cp_bt_y">适用于大部分新能源汽车</dl>
          </div>
          <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_gbqk.png" class="tp" />
      </div>
  </div> -->
  </div>
  <!-- xhx_aqwd -->
  <div id="g7">
    <div
      class="shz_jyc"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jyc_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="r">
          <dd class="cp_bt_d">TPE枪线绝缘层，高温不软化</dd>
          <dl class="cp_bt_y">出色握感，符合人体工程学设计</dl>
        </div>
      </div>
      <img
        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jyc.png"
        class="tp"
      />
    </div>
  </div>
  <!-- xhx_lxbz -->
  <div id="g8">
    <div
      class="cp_f_bg"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_zncx_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="t">
          <dd>智慧出行 与你同行</dd>
          <dl>
            安装网络覆盖全国360个城市,已为100万户家庭生产并安装了家庭充电桩！
          </dl>
          <ol>
            <a @click="$U.openProductFile('shouhuzhe')"
              >产品手册<i class="fa fa-angle-right"></i
            ></a>
          </ol>
        </div>
        <!-- <div class="f">
              <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_zncx.png" />
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
export default {
  name: "shouhuzhe",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    onMounted(() => {
      document.title = "守护者";
      // 滚动到页面最上方
      window.scrollTo(0, 0);
      $(".banner_cp .width1480 .r ol.f dt").click(function () {
        var liindex = $(".banner_cp .width1480 .r ol.f dt").index(this);
        $(this).addClass("o").siblings().removeClass("o");
        $(".banner_cp .width1480 .l dl")
          .eq(liindex)
          .fadeIn(150)
          .siblings("dl")
          .hide();
      });
      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (var i = 1; i < 20; i++) {
          if ($("#g" + i) && $("#g" + i).offset()) {
            //加循环
            if ($("#g" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#g" + i).addClass("on"); //给当前导航加c类
            } else {
              //$("#p"+i).removeClass('on');
            }
          }
        }
      });
    });
    return {};
  },
};
</script>

<style lang="less" scoped></style>
